.b-play_button {
	align-items: center;
	background-color: rgba($color-bg-overlay, 0.32);
	color: $color-white;
	cursor: pointer;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	
	.b-product_gallery-thumb & {
		background-color: rgba($color-bg-overlay, 0.25);

		svg {
			height: 40px;
		}
	}

	.b-product_video-cover &,
	.b-video_content-cover & {
		background-color: transparent;

		svg {
			@include media(md-up) {
				height: 100px;
				width: 100px;
			}
		}
	}
}
