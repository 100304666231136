@charset "UTF-8";
/*md
@no-stat

# Breakpoints

## Boilerplate breakpoints

Boilerplate has 4 main breakpoints that targeted to [supported devices](https://confluence.ontrq.com/display/RSB/SFRA+BP+-+Supported+Browsers+and+Devices)
 - iPhone X, iPad, MS Windows desktop / Macbook Pro retina

** Please not iPad landscape - is LG breakpoint **

[See more info](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

## Supported screen resolutions

Boilerplate is come "Retina ready". It supports MDPI and XHDPI pixel density across all site.

| Device             | Screen Resolution, CSS pixels | Pixel density |
|--------------------|-------------------------------|---------------|
| Desktop Windows PC | 1920x1080                     | MDPI          |
| Macbook pro 13     | 1280x800 / 1440x900           | XHDPI         |
| iPad Air 2         | 1024x768                      | XHDPI         |
| iPhone X           | 375x812                       | XHDPI         |
| Samsung Galaxy S9  | 360x740                       | XHDPI         |

*/
/*md
@no-stat

# Media queries (breakpoints)

We have a `media` mixin for make it easier to implement responsive styling via media queries.

You can nest them right within other blocks of CSS,which puts the properties and values you are changing right next
to each other.
That creates an obvious connection between them, which is a much nicer authoring experience than trying to maintain
those changes separated by tons of other code or in a different file.

## Configuration

**Site Layout Conception** details with examples you can find [here](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

`media` mixin works with `$media` map where `media-name: media query`

This is how `$media` map looks:

```scss
$media: (
	sm: 'screen and (max-width: 767px)',
	md: 'screen and (min-width: 768px) and (max-width: 1199px)',
	lg: 'screen and (min-width: 1200px)',
	xl: 'screen and (min-width: 1201px)',
	md-up: 'screen and (min-width: 768px)',
	md-down: 'screen and (max-width: 1023px)',
	lg-up: 'screen and (min-width: 1024px)',
	lg-down: 'screen and (max-width: 1367px)'
);
```

## Usage

Here is how to use `media()` mixin:

```scss
.b-block {
	// styles outside of a media query

	@include media(sm) {
		// styles for "s" viewports
	};

	@include media(md-up) {
		// styles for "m" and "l" viewports
	};
}
```

Simply edit this file and add your own media queries to `$media` map.

*/
/*md
@no-stat

# Palette

This is palette settings for project/brand. It divided into 2 main categories palette and applied color.

* Palette is general set of colors. It could be used directly if you do not have themes.
* Applied colors designed as layer of abstraction to have ability to overwritten on brand level.

*/
/*md
@no-stat

# Globals variables

This variables are set of different global settings that is used across sets of components.

It include:

* globals
* depth of components (box-shadow)
* motion of components

*/
/*md
@no-stat

# Rh (Indents rhythm)

This function is designed to keep consistency of vertical and horizontal indents in the project.

Not all values are identical in design, sometimes 20px become 21px, 19px, 22px etc. It does not make
any sense to implement it as is. To keep indents consistent we need to round this values to 4px steps.

Ex: in design 22px / 19px -> rh(5) => 20px; in design 23px -> rh(6) => 24px etc.

This is alternate approach to `$space-md: 10px; $space-lg: 20px;`.

Designers should use the rhythm in his work.

## Usage

```scss
.component {
	@include rh(2); // => 8px
	@include rh(2 4 0); // => 8px 16px 0
}
```
*/
/* stylelint-disable */
/* stylelint-enable */
/*md
@no-stat

# Z-indexes

Z-index is an inherently tricky thing, and maintaining z-index order in a complex layout is difficult.
With different stacking orders and contexts, keeping track of them as their numbers increase can be hard.
<br />
<br />
We use sass function to help manage z-indexes from single place.
The most important requirement of this technique is sticking to it.
Any rogue hard-coded z-index values could compromise the integrity of those derived from your list.

## Usage

**We don't use hardcoded integer `z-index` values. Instead, we use indexes from the map `$z-indexes`**

### 1. Set up `$z-indexes` map
```scss
$z-indexes: (
    components: (
        component_name: (),
        checkbox: (
            before: (),
            after: (),
            icon: (),
        )
    ),
    content: (),
    popup-menu: ()
);
```

### 2. Add values in SCSS classes using `z()` function

#### Global components
```scss
.b-components { z-index: z(components); }
.b-content { z-index: z(content); }
.b-pop_up-menu { z-index: z(popup-menu); }
```

#### Inside a component
```scss
.b-component_name { z-index: z(components, component_name); }
.b-checkbox {
    &-before { z-index: z(components, checkbox, before); }
    &-after { z-index: z(components, checkbox, after); }
    &-icon { z-index: z(components, checkbox, icon); }
}
```

### 3. Get resulting CSS
```scss
.b-components { z-index: 1; }
.b-content { z-index: 2; }
.b-pop_up-menu { z-index: 3; }

.b-component_name { z-index: 1; }
.b-checkbox-before { z-index: 1; }
.b-checkbox-after { z-index: 2; }
.b-checkbox-icon { z-index: 3; }
```

*/
/*md
@no-stat

# Grids

## How to setup grids config for project

### Several grid configs for project

We can use several grid configs per project. For that, we need to add a new grid name to the `$grids` map with settings.

### Gaps / margin / column span configuration:

```scss
$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 16px 9px),
		grid-margin:  ('xl': 88px, 'lg': 24px, 'md': 32px, 'sm': 15px),
	)
);
```

### Grid-span configuration

Please see details [grid-span](01-core-functions-grid-span.html)

## Work with grids

### Development approaches

#### 1. Using `g-grid` mixin

With features of `display: grid`. Please see [g-grid](02-components-g-grid.html) details.

#### 2. Using `grid-span` function

Could be used in conjunction with different display properties while maintaining their common features(floating, centering, etc.). Please see [grid-span](01-core-functions-grid-span.html) details.

### Get gaps / margin / column span

For that we have the next grid functions in `_grids_tools.scss`:
- grid-gutter
- grid-margin
- grid-columns

Please see [grid functions](00-configuration-grids_tools.html) details with usage examples.

### Examples of usage

Please see [ready-made tools](05-blocks-guide-l-cols.html) details.

*/
/*md
@no-stat

# grid-* (grid config get functions)

This functions designed to get parameters from grid configuration config and
use it for creating grids or reuse grid configuration into different components.

* `grid-gutter`
* `grid-columns`
* `grid-margin`

## Usage

```scss

// Configuration:

$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	),
	altered: (
		grid-columns: ('xl': 10,   'lg': 10,   'md': 10,   'sm': 6),
		grid-gutter:  ('xl': 10px, 'lg': 10px, 'md': 10px, 'sm': 10px),
		grid-margin:  ('xl': 40px, 'lg': 30px, 'md': 30px, 'sm': 20px),
	)
);

// Usage:

.component {
	padding: grid-gutter('lg'); // => grids -> 'default' -> grid-gutter -> lg = 20px
	padding: grid-gutter('lg', 'altered'); // => => grids -> 'altered' -> grid-gutter -> lg = 10px
}

.component-b {
	margin: grid-margin('lg');
	margin: grid-margin('lg', 'altered');

	@include media(sm) {
		margin: grid-margin('sm');
	}
}

.component-c {
	width: percentage(grid-columns('lg') / 4);

	@include media(sm) {
		width: percentage(grid-columns('sm') / 2);
	}
}
```
*/
/*md
@no-stat

# adjust-color-to-bg

This function used to adjust color depending on provided background color. It use
luminance human persived criteria as breakpoint for colors
[See more details](http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef).

It is especially useful for crating flexible themes.

## Arguments

```
$backgroundColor - bg color
$colorInverse - color if bg is dark. If not provided would return $color-white
$colorNormal - color if bg is light. If not provided would return $color-text

adjust-color-to-bg($backgroundColor, $colorInverse, $colorNormal)
```

## Usage

```scss
.component {
	color: adjust-color-to-bg($color-bg-header-line-1);

	// => results default 'white' if background dark
	// => results default 'black' if background is light
}

.component-custom-inverse-color {
	color: adjust-color-to-bg($color-bg-footer, grey);

	// => results 'grey' if background dark
	// => results default 'black' if background is light
}

.component-all-custom-colors {
	color: adjust-color-to-bg($color-bg-footer, green, red);

	// => result 'green' if background dark
	// => result 'red' if background is light
}
```

Based on Hugo Giraudel [work](https://css-tricks.com/snippets/sass/luminance-color-function/)
*/
/*md
@no-stat

# grid-span

`grid-span` function returns value which could be used as **width, max-witdth, flex-basis, etc.**

### Parameters
```scss
@function grid-span($column: 1, $break: 'lg', $with-gutter: false, $grid: 'default')
```

## Examples

### Flex-basis example

```scss
.b-grid {
	display: flex;

	.b-grid__item {
		flex-basis: grid-span($column: 3);
	}
}
```

### Floated items example

```scss
.b-grid {
	.b-grid__item {
		float: left;
		width: grid-span($column: 2);
	}
}
```

### Inline-block items example

```scss
.b-grid {
	.b-grid__item {
		display: inline-block;
		max-width: grid-span($column: 2);
	}
}
```

*/
/*md
@no-stat

# aspect-ratio

This function used to get percentage value of aspect ratio color to use in `padding` to
create container for images.

This technique used to prevent content bouncing during load and create layout shifts.

Calculation. 16:9 Aspect Ratio would result `(9 / 16) * 100 = 0.5625%`.

See proposed [specs](https://drafts.csswg.org/css-sizing-4/#aspect-ratio)

## Arguments

```
$width - width of element
$height - height of element

=> percentage

aspect-ratio($width, $height)
```

## Usage

```scss
.component {
	padding-bottom: aspect-ratio(16, 9);
	padding-bottom: aspect-ratio(1920, 1080);
	padding-bottom: aspect-ratio(1920px, 1080px);
}

.b-suggestions-item_image {
	@include g-image_container(_container, aspect-ratio(16, 9));

	img {
		@include g-image_container(_img);
	}
}
```

*/
/*md
@no-stat

# Hide

This mixin is especially useful for hiding text
or visually hide needed elements

Here is a list of parameters you can use:

* text - helps to hide text without loosing visibility for parsers
* visually - like for text but for the whole element

## Usage

```scss
.component {
	@include hide(visually);
}

.h-hide_vis {
	@include hide(visually, true);
}
```
*/
/*md
@no-stat

# Hover-supported

This mixin is designed to address iOS standard behaviour of first tap - hover,
second tap - click that is engaged when control has hover styles applied.

This is critical for functionality like back-top-button. If we apply hover styles as is.
It would be activated only after second tap.

If rules are wrapped into this media it applied only in case if device have fine
pointer mechanism. [See more info](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer).

Please note about mixed input devices - touch screen + mouse + touchpad,
touchpad + trackpoint, touch screen + stylus ("apple pencil") etc. -
sometimes browser do not report it properly, so logic should be builded around
exclusions.

## Usage

```scss
.component {
	@include hover-supported {
		&:hover {
			// Hover styles that should not be applied to touch
		}
	};
}
```
*/
/*md
@no-stat

# Line clamping

This mixin is useful for truncated text

Here is a list of parameters you can use:

* lines - the number of rows to display

## Usage

```scss
.component {
	@include line-clamping($lines: 3);
}

*/
/*md

# g-button

Designed to provide same styles of buttons across different components.
It is possible to use with `<button>` or `<a>` elements

## First type button

```html_example
<button type="submit" class="b-button">
	Sign in
</button>
```

## First type disabled button

```html_example
<button type="submit" class="b-button m-disabled">
	Sign in
</button>
```

## First type, full width button

```html_example
<button type="submit" class="b-button m-width_full">
	Sign in
</button>
```

## Second type button

```html_example
<button type="submit" class="b-button m-outline">
	Sign in
</button>
```

## Second type disabled button

```html_example
<button type="submit" class="b-button m-outline m-disabled">
	Sign in
</button>
```

## Second type, full width button

```html_example
<button type="submit" class="b-button m-outline m-width_full">
	Sign in
</button>
```

## Link button

```html_example
<button type="submit" class="b-button m-link">
	Sign in
</button>
```

## Medium height Link button

```html_example
<button type="submit" class="b-button m-link m-medium">
	Sign in
</button>
```

## Small height Link button

```html_example
<button type="submit" class="b-button m-link m-small">
	Sign in
</button>
```

*/
/*md

# g-button_iconed

Designed to provide same styles of buttons that contain only icon (without any text)
across different components without explicit CSS class.

It is used for header menubar icons, hamburger menu items and dialog close button.

```scss
.b-dialog {
	// ...
	&-close {
		@include g-button_iconed;
	}
}
```
*/
/*md

# g-radio

The component is generally used for choosing item which includes in the radio group.

## Usage

Only one g-radio in a given group can be selected at the same time.

If user selects one option in the list that other options come to unselected.

`g-radio` has states: unchecked, checked, hover, disabled and invalid.

## Unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-2" class="b-radio-input"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-2">Some text</label>
</div>
```

## Checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-3" class="b-radio-input" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-3">Some text</label>
</div>
```

## Disabled unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-4" class="b-radio-input" disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-4">Some text</label>
</div>
```

## Disabled checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-5" class="b-radio-input" checked disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-5">Some text</label>
</div>
```

## Invalid unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-6" class="b-radio-input m-invalid"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-6">Some text</label>
</div>
```

## Invalid checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-7" class="b-radio-input m-invalid" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-7">Some text</label>
</div>
```

## Customization by SCSS

Radio button styles that used in several component.

Designed to use same style of radio in different components
ex: b-radio, b-payment_option, b-shipping_option, b-stores etc.

It provide styles only for icon element based on input node.

```scss_example
.b-option_switch {
	// ...
	&-input {
		@include g-radio(_input);
	}

	&-icon {
		@include g-radio(_icon);

		.b-option_switch-input:active + & {
			@include g-radio(_icon, m-active);
		}

		.b-option_switch-input:hover + & {
			@include g-radio(_icon, m-hover);
		}

		.b-option_switch-input:checked + & {
			@include g-radio(_icon, m-checked);
		}

		.b-option_switch-input[disabled] + & {
			@include g-radio(_icon, m-disabled);
		}
	}
}
```
*/
/*md

# g-checkbox

This component allows user to choose between two mutually exclusive state (checked or unchecked).

## Usage

A `g-checkbox` is used for select or unselect action items.

It is always tied to `<label>` that describes two opposite states.

The component usually points to choose settings or preferences.

`g-checkbox` has states (unchecked, checked, hover, disabled and invalid).

## Unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-1" />
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-1">Some text</label>
</div>
```

## Checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-2" checked/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-2">Some text</label>
</div>
```

## Disabled unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-3" disabled/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-3">Some text</label>
</div>
```

## Disabled checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-4" checked disabled/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-4">Some text</label>
</div>
```

## Invalid unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-5"/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-5">Some text</label>
</div>
```

## Invalid checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-6" checked/>
	<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-6">Some text</label>
</div>

```

## Customization by SCSS

Checkbox styles that used in several component.

Designed to use same style of checkbox in different components without additional CSS class.
ex: `b-checkbox`, `b-refinement_checkbox`, `b-account_preference` etc.

It provide styles only for icon element based on SVG.

```scss
.b-refinement_checkbox {
	// ...
	&-icon {
		@include g-checkbox(_icon);

		.b-refinement_checkbox:active & {
			@include g-checkbox(_icon, m-active);
		}

		.b-refinement_checkbox.m-checked & {
			@include g-checkbox(_icon, m-checked);
		}

		.b-refinement_checkbox.m-disabled & {
			@include g-checkbox(_icon, m-disabled);
		}
	}
}
```
*/
/*md

# g-spinner

Global spinner component applied to different blocks that fetch data.

Designed to use same style of spinner in different components and on particular breakpoints.
Ex: b-minicart_popup, b-suggestions, b-plp_grid, b-product_details, b-cart etc.

```scss
.b-product_gallery {
	&.m-loading_long::before {
		@include g-spinner();
	}
	// ...
}
```
*/
/*md

# g-link

Designed to provide same styles of text-type links across different components.

## Usage

```scss
.component-link {
	@include g-link;
}

.component-link {
	@include g-link(inherit);
}
```

*/
/*md

# g-link_hamburger

Hamburger menu generic link that used in several component.

Designed to use same style of hamburger link in different components
ex: menu, account link, language switcher etc.

```scss
.b-menu {
	// ...
	&-item {
		@include media(sm) {
			@include g-link_hamburger;
		}
	}
}
```
*/
/*md

# g-icon_gradient

Designed to provide same styles of icon gradient across different components without
explicit CSS class.

```scss
.b-user_greeting {
	// ...
	&-icon {
		@include g-icon_gradient;
	}
}
```
*/
/*md

# g-image_container

This is global component designed to hold image in place and preventing from layout bouncing during page load.

It based on `padding-bottom` trick. `padding-bottom` and `padding-top` relative units are based
on parent element `width`. So if you had an element that is 500px wide, and padding-top of 100%,
the padding-top would be 500px. [More info](https://css-tricks.com/aspect-ratio-boxes/)

```scss
.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}

.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}
```

You could change aspect ration in mixin:

```scss
@include g-image_container(_container, 100%);   // 1:1
@include g-image_container(_container, 150%);   // 2:3
@include g-image_container(_container, 133%);   // 3:4
@include g-image_container(_container, 125%);   // 5:4
@include g-image_container(_container, 75%);    // 4:3
@include g-image_container(_container, 66.6%);  // 3:2
@include g-image_container(_container, 56.25%); // 16:9
```

But it is preferable to define only one aspect ration through all images and not change it.

*/
/*md

# g-snap_scroll

Snap scroll functionality applied to different cases.

Designed to use same snap scroll functionality in different components and on particular breakpoints.
Ex: b-carousel, b-product_gallery, .b-product_slider etc.

```scss
.b-product_gallery {
	&-thumbs_track {
		@include g-snap_scroll($direction: y);
	}
	// ...
}
```

[Snap scroll MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scroll_Snap)

*/
/*md

# g-dialog_backdrop

Dialog window backdrop styles that used in several component.

Designed to use same style of backdrop and logic in different components and on particular breakpoints.
Ex: b-dialog, b-menu_panel, b-refinements_panel, b-minicart_panel etc.

```scss
.b-menu_panel {
	@include media(sm) {
		@include g-dialog_backdrop;
	}
	// ...
}
```
*/
/*md

# g-section_holder

This is global component designed to hold some standalone section of the site
as it wrapped into main container.

It could be used not only for standalone blocks, but also for page layouts.

```scss
.b-section {
	background: green;

	&-inner {
		@include g-section_holder;
	}
}
```
*/
/*md

# g-section_holder_header

Since header is differs from other container (g-section_holder)
we need special component the same as `section_holder` but with different
`max-width` and `margin`s.

This is global component designed to hold header of the site as it wrapped into
main container.

This common designs it could be removed and changed to `section_holder`.

```scss
.l-header-inner {
	background: green;

	&-inner {
		@include g-section_holder_header;
	}
}
```
*/
/*md

# g-section_holder_footer

Since footer is differs from other container (g-section_holder)
we need special component the same as `section_holder` but with different
`max-width` and `margin`s.

This is global component designed to hold footer of the site as it wrapped into
main container.

This common designs it could be removed and changed to `section_holder`.

```scss
.l-footer-inner {
	background: green;

	&-footer {
		@include g-section_holder_footer;
	}
}
```
*/
/*md

# g-heading_*

Some basic simple typography applied to different UI components.

This covers only very basic cases and could be extended.

```scss
.b-cart_empty {
	// ...

	&-title {
		@include g-heading_1;

		margin-bottom: rh(8);
	}
}
```
*/
/*md

# g-accordion

Global accordion component

## Attributes

```
data-allow-toggle="true" - Flag that allow or dissallow toggle
data-open-first="true" - Flag that open first item
data-allow-multiple="true" - Flag that allow or dissallow multiple open items
```

## Simple accordion example

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="false"
    data-open-first="true"
    data-allow-multiple="false"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Accordion with multiple open items

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="true"
    data-open-first="false"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Init accordion on sm, md & lg devices

### Attributes

```
.b-accordion
	data-widget="accordion" - init for all viewports
	data-widget.sm="accordion" - init for sm viewports
	data-widget.sm.md="accordion" - init for sm & md viewports
	data-widget.sm.md.lg="accordion" - init for sm & md & lg viewports
.b-accordion-item
	data-widget="accordionItem" - init for all viewports
	data-widget.sm="accordionItem" - init for sm viewports
	data-widget.sm.md="accordionItem" - init for sm & md viewports
	data-widget.sm.md.lg="accordionItem" - init for sm & md & lg viewports
```

```html_example
<div
    data-id="descriptions"
    data-widget.sm.md.lg="accordion"
    data-allow-toggle="true"
    data-open-first="true"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Customization by SCSS

This implementation allow to use accordion for one vieport and any other component for rest viewports

```scss
.b-accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(_item);
	}

	&-title {
		@include g-accordion(_control);
	}

	&-content {
		@include g-accordion(_content);

		&[aria-hidden='false'] {
			@include g-accordion(_content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(_content_inner);
	}
}
```
*/
/*md

# g-grid

Grid layout component based on CSS grid.

It is designed to easy use project defined grid into components where CSS grid is
applicable.

```scss
.b-grid {
	@include g-grid();

	.b-columns__item {
		@include media(lg-up) {
			grid-column: 2 / span 4;
			grid-row: 1 / 2;
		}

		@include media(md-down) {
			grid-column: grid-start / span 7;
		}
	}
}
```
*/
/*md
@no-stat

# Header item

This mixin is useful for header items with icon

## Usage

```scss
.component {
	@include g-header_item();
}

*/
/*md
@no-stat

# Header item element in Hamburger Menu

## Usage

```scss
.component {
	@include g-header_item_menu;
}

*/
/*md

# g-title

Designed to provide same styles of title across different components.

## Usage

```scss
.component {
	@include g-title(true);
}
```

*/
/*md

# g-loader

Designed to provide same styles of loading indicator across different components.

## Usage

```scss
.component {
	@include g-loader;
}
```

*/
.l-search {
  margin: 0 auto;
  max-width: 1600px;
  padding-left: 88px;
  padding-right: 88px;
  margin-bottom: 75px;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .l-search {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-search {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .l-search {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 1367px) {
  .l-search {
    max-width: 1360px;
  }
}
.l-search-content {
  position: relative;
}
.l-search-content::after {
  background-color: rgba(255, 255, 255, 0.6);
  bottom: 0;
  content: "";
  cursor: wait;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  visibility: hidden;
}
.l-search-content.m-busy::after {
  opacity: 1;
  visibility: visible;
}
.l-search-noresult {
  margin: 0 auto 25px;
  width: 100%;
}
.l-search-noresult .l-section {
  margin: 0 auto;
  max-width: 1600px;
  padding-left: 88px;
  padding-right: 88px;
  margin-top: 40px;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .l-search-noresult .l-section {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-search-noresult .l-section {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .l-search-noresult .l-section {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.l-search_list {
  margin-top: 72px;
}
@media screen and (min-width: 1024px) {
  .l-search_list-content {
    width: 58.3333333333%;
  }
}
.l-search_list-item {
  margin-bottom: 28px;
}

.l-plp {
  border-top: 1px solid #D2D2D2;
  margin-top: 20px;
}
@media screen and (min-width: 1024px) {
  .l-plp {
    display: flex;
  }
}
@media screen and (min-width: 768px) {
  .l-plp {
    margin-top: 25px;
  }
}
.l-plp.m-hybrid {
  border: none;
}
@media screen and (min-width: 1024px) {
  .l-plp-refinement {
    display: flex;
    flex-direction: column;
    max-width: 221px;
    position: relative;
    width: 100%;
  }
}
@media screen and (max-width: 1023px) {
  .l-plp-refinement {
    bottom: 0;
    display: flex;
    height: 100dvh;
    justify-content: flex-end;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
    transition-property: visibility, background-color;
    visibility: hidden;
    width: 100vw;
    z-index: 11;
    /* stylelint-disable */
    /* stylelint-enable */
  }
  .l-plp-refinement .b-refinements_panel-sticky {
    background-color: #FFFFFF;
    height: 100%;
    max-height: 100vh;
    max-width: 400px;
    top: 0;
    transform: translateX(100%);
    transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
    width: 75vw;
  }
  .l-plp-refinement.-active {
    background: rgba(0, 0, 0, 0.5);
    pointer-events: all;
    visibility: visible;
  }
  .l-plp-refinement.-active .b-refinements_panel-sticky {
    transform: translateX(0);
  }
}
.l-plp-refinement.m-no_results {
  display: none;
}
.l-plp-products {
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .l-plp-products {
    padding-left: 20px;
  }
  .l-plp-products.m-no_results {
    margin: 20px 0 75px;
    width: 100%;
  }
}
.l-plp-products.m-no_results {
  margin-bottom: 0;
  padding: 0;
}

.l-plp_grid {
  align-items: stretch;
  display: grid;
  grid-auto-flow: dense;
  grid-gap: 30px 8px;
  grid-template-areas: ". . slot1 slot1";
  grid-template-columns: repeat(4, minmax(0, 1fr));
  justify-items: stretch;
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-plp_grid {
    grid-gap: 30px 16px;
    grid-template-areas: ". slot1 slot1";
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media screen and (min-width: 1024px) {
  .l-plp_grid {
    grid-gap: 30px 20px;
  }
}
@media screen and (max-width: 767px) {
  .l-plp_grid {
    grid-gap: 20px 8px;
    grid-template-areas: "slot1 slot1";
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.l-plp_grid::after {
  background-color: rgba(255, 255, 255, 0.6);
  bottom: 0;
  content: "";
  cursor: wait;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  visibility: hidden;
}
.l-plp_grid.m-busy::after {
  opacity: 1;
  visibility: visible;
}
@media screen and (max-width: 767px) {
  .l-plp_grid.m-one_column {
    grid-gap: 30px 8px;
    grid-template-areas: "slot1";
    grid-template-columns: 1fr;
  }
}
@media screen and (min-width: 1024px) {
  .l-plp_grid.m-three_column {
    grid-template-areas: ". slot1 slot1";
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.l-plp_grid-actions {
  grid-column: 1/-1;
}
.l-plp_grid-slot_1 {
  align-self: start;
  grid-area: slot1;
  height: initial;
}

/*md

# Tabs (`b-tab_list` / `b-tab_panel`)

Tabs presents multiple mutually exclusive panes of content in the same area.
Includes a tabbed control(tab) and a content area. Clicking a tab displays its corresponding pane in the content area.

## Attributes

```
[boolean] - data-active-first - activate first tab and first tab panel
[string]  - data-active-panel - activate tab and tab panel by provided panel id
[boolean] - data-auto-activation - if tabs list should follow accessibility `Tabs with Automatic Activation` feature
```

## Usage

To get started with tabs we should link the tab and the content area.
We have a `data-panel-name` attribute on a tab and `id` attribute on the pane for that.

### Tabs with automatic activation (data-auto-activation="true")

The tab pane content will change just in case of using arrow keys.
With using just a Tab key, inactive tabs will be skipped by focus.
Click functionality works as usual.

```html_example
<div
    data-widget="tabs"
    data-auto-activation="true"
    data-active-panel="firstPanel"
    data-event-keydown="handleKeydown"
>
    <div data-ref="tablist" role="tablist" class="b-tab_list">
        <button aria-selected="true"
                class="b-tab_list-tab"
                data-panel-name="firstPanel"
                data-widget-event-click="handleTabClick"
                data-id="button-firstPanel"
                data-event-click.prevent="handleClick"
                data-widget="button"
                role="tab"
        >
            First tab
        </button>

        <button
                tabindex="-1"
                aria-selected="false"
                class="b-tab_list-tab"
                data-panel-name="secondPanel"
                data-widget-event-click="handleTabClick"
                data-id="button-secondPanel"
                data-event-click.prevent="handleClick"
                data-widget="button"
                role="tab"
        >
            Second tab
        </button>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="firstPanel"
    >
        <h1>The content of a FIRST tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.</p>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="secondPanel"
    >
        <h1>The content of a SECOND tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua.</p>
    </div>
</div>
```

### Tabs with manual activation (data-auto-activation="false")

The tab pane content will change just in case of using Enter/Space button on focused element.
Click functionality works as usual.

```html_example
<div
    data-widget="tabs"
    data-auto-activation="false"
    data-event-keydown="handleKeydown"
    data-active-panel="firstPanel"
>
    <div data-ref="tablist" role="tablist" class="b-tab_list">
        <button aria-selected="true"
                class="b-tab_list-tab"
                data-panel-name="firstPanel"
                data-widget-event-click="handleTabClick"
                data-id="button-firstPanel"
                data-event-click.prevent="handleClick"
                data-widget="button"
                role="tab"
        >
            First tab
        </button>

        <button
            aria-selected="false"
            class="b-tab_list-tab"
            data-panel-name="secondPanel"
            data-widget-event-click="handleTabClick"
            data-id="button-secondPanel"
            data-event-click.prevent="handleClick"
            data-widget="button"
            role="tab"
        >
            Second tab
        </button>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="firstPanel"
    >
        <h1>The content of a FIRST tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.</p>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="secondPanel"
    >
        <h1>The content of a SECOND tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua.</p>
    </div>
</div>
```

## SCSS Notes

We have two SCSS blocks for Tabs. The first is for tab controls `b-tab_list` , and the second is fortab content
areas `b-tab_panel`.
*/
.b-tab_list {
  display: flex;
  justify-content: center;
  margin: 16px auto;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  user-select: none;
  width: 100%;
}
.b-tab_list::-webkit-scrollbar {
  display: none;
}
.b-tab_list.m-account {
  margin-bottom: 28px;
}
.b-tab_list-tab {
  appearance: none;
  background: transparent;
  border: none;
  border-bottom: 1px solid #45132C;
  border-radius: 0;
  color: #2F2F2F;
  cursor: pointer;
  display: block;
  flex-basis: 50%;
  font-size: 17px;
  line-height: 1.5;
  margin-bottom: 1px;
  padding: 6px 15px;
  position: relative;
  text-decoration: none;
  text-transform: initial;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
@media screen and (max-width: 767px) {
  .b-tab_list-tab {
    width: 100%;
  }
}
.b-tab_list-tab:hover {
  color: #45132C;
}
.b-tab_list-tab.m-active {
  box-shadow: 0 -3px 0 0 #45132C inset;
  color: #45132C;
  font-weight: bold;
}
.b-account .b-tab_list-tab {
  white-space: nowrap;
}
.b-account .b-tab_list-tab.m-active {
  border-bottom-width: 4px;
  box-shadow: none;
}

.b-tab_panel {
  display: none;
  width: 100%;
}
.b-tab_panel.m-active {
  display: block;
}

.b-load_progress {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
}
.b-load_progress-description {
  color: #757575;
  font-size: 13px;
  line-height: 1.4;
  margin-bottom: 20px;
}

.b-load_more {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  text-align: center;
}
.b-load_more-button {
  padding: 0 26px;
}
.b-load_more-link {
  cursor: pointer;
  text-decoration: underline;
  color: #45132C;
  font-size: 15px;
  font-weight: 600;
  line-height: 22.5px;
  margin-left: 55px;
}
@media not all and (pointer: coarse) {
  .b-load_more-link:hover {
    color: #C23D74;
  }
}
.b-history_page .b-load_more {
  margin-bottom: 51px;
}

.b-header_category {
  margin: 0 auto;
  max-width: 1600px;
  padding-left: 88px;
  padding-right: 88px;
  margin-top: 20px;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-header_category {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-header_category {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-header_category {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 1367px) {
  .b-header_category {
    margin-top: 40px;
    max-width: 1360px;
  }
}
@media screen and (max-width: 767px) {
  .b-header_category {
    overflow: hidden;
  }
}
.b-header_category-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  color: #45132C;
}
.m-category-tiles .b-header_category-title {
  text-align: center;
}
.b-header_category-description {
  color: #2F2F2F;
  font-size: 13px;
  line-height: 1.45;
  margin-bottom: 20px;
  margin-top: 10px;
  position: relative;
}
.b-header_category-description.m-cut-active {
  padding-bottom: 20px;
}
.m-category-tiles .b-header_category-description {
  text-align: center;
}
.b-header_ext .b-header_category-description {
  margin-top: -10px;
}
.b-header_category-description_content {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 20px;
}
@media screen and (min-width: 1024px) {
  .b-header_category-description_content {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 40px;
  }
}
.b-header_category-description_content.m-show-more {
  display: block;
  max-height: none;
}
.b-header_category-description_link {
  bottom: 0;
  color: #45132C;
  font-weight: 600;
  left: 0;
  position: absolute;
}
.m-category-tiles .b-header_category-description_link {
  position: relative;
}

/*md

# b-promo_box

`promo-box` is basic container for creating promo boxes and banners.
It designed to position description (`b-promo_caption`) over the image box.

This implementation could handle both image and text centric approaches.

## Example

```html_example
<figure class="b-promo_box m-caption_center">
	<picture class="b-promo_box-picture">
		<source type="image/jpeg" media="(max-width: 767px)"
				srcset="../images/guide/examples/banner-16x9-sm.jpg?$staticlink$, ../images/guide/examples/banner-16x9-sm@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 768px) and (max-width: 1024px)"
				srcset="../images/guide/examples/banner-16x9-md.jpg?$staticlink$, ../images/guide/examples/banner-16x9-md@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 1025px)"
				srcset="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$, ../images/guide/examples/banner-16x9-lg@2x.jpg?$staticlink$2x" />
		<img
			loading="lazy"
			src="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

## Overflow handling

This component is designed to handle any type of overlow without cutting text content.

### Very long text

```html_example
<figure class="b-promo_box m-caption_offcenter">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities and other thing into long long title with some additional details
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app. <br/>
		</p>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

### Different aspect ratio of image

```html_example
<figure class="b-promo_box">
	<picture class="b-promo_box-picture" style="padding-bottom:10%">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

## Horizontal alignment

### `m-caption_left`

```html_example
<figure class="b-promo_box m-caption_left">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

### `m-caption_right`

```html_example
<figure class="b-promo_box m-caption_right">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

### `m-caption_center`

```html_example
<figure class="b-promo_box m-caption_center">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

### `m-caption_offcenter`

```html_example
<figure class="b-promo_box m-caption_offcenter">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/image-placeholder.svg"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

## Vertical alignment

For sake of simpleness and robustness Boilerplate do not provide predefined vertical alignment
variations.

## Video as base

It is not limited what you could use as base for banner - it could be image or video.

What you need to do:

* `autoplay loop muted playsinline` is required to auto play video without user gesture in iOS

Please see [iOS manual](https://webkit.org/blog/6784/new-video-policies-for-ios/)

```html_example
<figure class="b-promo_box">
	<div class="b-promo_box-picture">
		<video autoplay loop muted playsinline width="1600" height="800">
			<source src="../../images/guide/examples/banner-video-16x9-lg.mp4" type="video/mp4" />
			<source src="../../images/guide/examples/banner-video-16x9-lg.mov" type="video/quicktime" />
		</video>
	</div>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

## Advanced: tiled background

```html_example
<style>
	.b-promo_box.m-image_3x4_right {
		.b-promo_box-picture {
			grid-column: 8 / span 4;
			padding-bottom: aspect-ratio(3, 4);
			padding-bottom: 133.3333333333%;
		}
	}
	.b-promo_box.m-image_3x4_left {
		.b-promo_box-picture {
			grid-column: 1 / span 4;
			padding-bottom: aspect-ratio(3, 4);
			padding-bottom: 133.3333333333%;
		}
	}
</style>
<figure class="b-promo_box m-caption_left m-image_3x4_right m-text_below">
	<picture class="b-promo_box-picture">
		<img
			loading="lazy"
			src="../images/guide/examples/banner-3x4-5.jpg?$staticlink$"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
    <figcaption class="b-promo_box-caption b-promo_caption">
        <h2 class="b-promo_caption-title">
            New beauty neutrals color
        </h2>
        <p class="b-promo_caption-subtitle">
            Plunge into calm pastel colors, choose for yourself only the most sophisticated and beautiful attire in the new season
        </p>
        <div class="b-promo_caption-actions">
            <a
                class="b-button"
                href="$url('Search-Show', 'cgid', 'category')$"
            >
                Shop New Season
            </a>
        </div>
    </figcaption>
</figure>
```

## Advanced: Background stretched independent from container

```html_example
<figure class="b-promo_box m-full_bleed m-caption_offcenter">
	<picture class="b-promo_box-picture">
		<source type="image/jpeg" media="(max-width: 767px)"
				srcset="../images/guide/examples/banner-16x9-sm.jpg?$staticlink$, ../images/guide/examples/banner-16x9-sm@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 768px) and (max-width: 1024px)"
				srcset="../images/guide/examples/banner-16x9-md.jpg?$staticlink$, ../images/guide/examples/banner-16x9-md@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 1025px)"
				srcset="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$, ../images/guide/examples/banner-16x9-lg@2x.jpg?$staticlink$2x" />
		<img
			loading="lazy"
			src="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
    <figcaption class="b-promo_box-inner">
        <div class="b-promo_box-caption b-promo_caption">
            <h2 class="b-promo_caption-title">
                Make boilerplate better
            </h2>
            <p class="b-promo_caption-subtitle">
                Everyone's fallen for boilerplate so we added to her games repertoire for spring with new playful styles
                inspired by darts and that staple of a British pub - the fruit machine.
            </p>
            <div class="b-promo_caption-actions">
                <a
                    class="b-button"
                    href="$url('Search-Show', 'cgid', 'category')$"
                >
                    Shop New Season
                </a>
            </div>
        </div>
    </figcaption>
</figure>
```
*/
.b-promo_box {
  display: grid;
}
@media screen and (min-width: 1367px) {
  .b-promo_box {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-promo_box {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-promo_box {
    grid-gap: 16px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767px) {
  .b-promo_box {
    grid-gap: 16px 9px;
    grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end];
  }
}
.b-promo_box-link {
  display: block;
}
@media not all and (pointer: coarse) {
  .b-promo_box-link:hover {
    text-decoration: none;
  }
}
.b-promo_box-picture {
  background: #F2F2F2;
  display: block;
  overflow: hidden;
  padding-bottom: 44.2477876106%;
  position: relative;
  width: 100%;
  grid-column: grid-start/grid-end;
  grid-row: 1/2;
}
@media screen and (max-width: 767px) {
  .b-promo_box-picture {
    padding-bottom: 100%;
  }
}
.b-promo_box-picture img,
.b-promo_box-picture video {
  bottom: 0;
  color: #F2F2F2;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-promo_box-caption {
  grid-column: grid-start/grid-end;
  grid-row: 1/2;
  padding: 30px 0;
  position: relative;
  text-align: center;
}
.b-promo_box.m-full_bleed {
  grid-template-columns: auto;
  height: 100%;
}
.b-promo_box.m-full_bleed .b-promo_box-picture {
  grid-column: 1/2;
  grid-row: 1/2;
  padding-bottom: 32.3162274619%;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-promo_box.m-full_bleed .b-promo_box-picture {
    padding-bottom: 58.3333333333%;
  }
}
@media screen and (max-width: 767px) {
  .b-promo_box.m-full_bleed .b-promo_box-picture {
    padding-bottom: 115.2%;
  }
}
.b-promo_box.m-full_bleed .b-promo_box-inner {
  display: grid;
  grid-column: 1/2;
  grid-row: 1/2;
  margin-bottom: 15px;
  margin-top: 15px;
  width: 100%;
}
@media screen and (min-width: 1367px) {
  .b-promo_box.m-full_bleed .b-promo_box-inner {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-promo_box.m-full_bleed .b-promo_box-inner {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-promo_box.m-full_bleed .b-promo_box-inner {
    grid-gap: 16px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767px) {
  .b-promo_box.m-full_bleed .b-promo_box-inner {
    grid-gap: 16px 9px;
    grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767px) {
  .b-promo_box.m-full_bleed .b-promo_box-inner {
    padding: 0 15px;
  }
}
.b-promo_box.m-hero_carousel {
  grid-template-columns: auto;
  height: 100%;
}
.b-promo_box.m-hero_carousel .b-promo_box-picture {
  grid-column: 1/2;
  grid-row: 1/2;
  padding-bottom: 32.3162274619%;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-promo_box.m-hero_carousel .b-promo_box-picture {
    padding-bottom: 58.3333333333%;
  }
}
@media screen and (max-width: 767px) {
  .b-promo_box.m-hero_carousel .b-promo_box-picture {
    padding-bottom: 70.4%;
  }
  .b-hero_carousel.m-one_item .b-promo_box.m-hero_carousel .b-promo_box-picture {
    padding-bottom: 59.7333333333%;
  }
}
.b-promo_box.m-hero_carousel .b-promo_box-inner {
  display: grid;
  grid-column: 1/2;
  grid-row: 1/2;
  margin-bottom: 15px;
  margin-top: 15px;
  width: 100%;
}
@media screen and (min-width: 1367px) {
  .b-promo_box.m-hero_carousel .b-promo_box-inner {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-promo_box.m-hero_carousel .b-promo_box-inner {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-promo_box.m-hero_carousel .b-promo_box-inner {
    grid-gap: 16px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767px) {
  .b-promo_box.m-hero_carousel .b-promo_box-inner {
    grid-gap: 16px 9px;
    grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767px) {
  .b-promo_box.m-hero_carousel .b-promo_box-inner {
    padding: 0 35px;
  }
}
@media screen and (max-width: 767px) {
  .b-promo_box:not(.m-full_bleed) .b-promo_box-caption {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.b-promo_box.m-caption_left .b-promo_box-caption {
  text-align: left;
}
@media screen and (min-width: 768px) {
  .b-promo_box.m-caption_left .b-promo_box-caption {
    grid-column: var(--column-start, 2)/span var(--column-end, 5);
  }
}
.b-promo_box.m-caption_right .b-promo_box-caption {
  text-align: left;
}
@media screen and (min-width: 768px) {
  .b-promo_box.m-caption_right .b-promo_box-caption {
    grid-column: 8/span 5;
  }
}
@media screen and (min-width: 768px) {
  .b-promo_box.m-caption_center .b-promo_box-caption {
    grid-column: 3/span 8;
  }
}
.b-promo_box.m-caption_offcenter .b-promo_box-caption {
  text-align: left;
}
@media screen and (min-width: 768px) {
  .b-promo_box.m-caption_offcenter .b-promo_box-caption {
    grid-column: 7/span 5;
  }
}
.b-promo_box.m-caption_top .b-promo_box-caption {
  align-self: start;
}
.b-promo_box.m-caption_middle .b-promo_box-caption {
  align-self: center;
}
.b-promo_box.m-caption_bottom .b-promo_box-caption {
  align-self: end;
}
@media screen and (max-width: 767px) {
  .b-promo_box.m-caption_top-sm .b-promo_box-caption {
    align-self: start;
  }
}
@media screen and (max-width: 767px) {
  .b-promo_box.m-caption_middle-sm .b-promo_box-caption {
    align-self: center;
  }
}
@media screen and (max-width: 767px) {
  .b-promo_box.m-caption_bottom-sm .b-promo_box-caption {
    align-self: end;
  }
}
@media screen and (min-width: 768px) {
  .b-promo_box.m-actions_columns {
    --actions-columns: 2;
  }
}
@media screen and (max-width: 767px) {
  .b-promo_box.m-actions_columns-sm {
    --actions-columns: 2;
  }
}
.b-promo_box.m-actions_width .b-promo_caption-actions {
  grid-auto-columns: minmax(250px, max-content);
  grid-template-columns: repeat(var(--actions-columns), minmax(var(--actions-btn-width, 200px), max-content));
}
@media screen and (max-width: 1366px) {
  .b-promo_box.m-actions_width .b-promo_caption-actions {
    --actions-btn-width: 150px;
  }
}
.b-promo_box.m-actions_center {
  --actions-width: fit-content;
  --column-start: 1;
}
@media screen and (min-width: 768px) {
  .b-promo_box.m-actions_center .b-promo_box-caption {
    padding-inline: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-promo_box.m-actions_center .b-promo_box-caption {
    --column-end: 6;
  }
}
@media screen and (min-width: 1024px) {
  .b-promo_box.m-actions_center .b-promo_box-caption {
    max-width: 90%;
  }
}
@media screen and (max-width: 767px) {
  .b-promo_box.m-text_below:not(.m-full_bleed) .b-promo_box-picture {
    grid-column: grid-start/grid-end;
  }
}
@media screen and (max-width: 767px) {
  .b-promo_box.m-text_below:not(.m-full_bleed) .b-promo_box-caption {
    grid-column: grid-start/grid-end;
    grid-row: 2/3;
    padding: 15px 0;
  }
}
@media screen and (max-width: 767px) {
  .b-promo_box.m-text_below.m-full_bleed {
    display: block;
  }
  .b-promo_box.m-text_below.m-full_bleed .b-promo_box-picture {
    grid-column: grid-start/grid-end;
  }
  .b-promo_box.m-text_below.m-full_bleed .b-promo_box-inner {
    grid-column: grid-start/grid-end;
    grid-row: 2/3;
  }
  .b-promo_box.m-text_below.m-full_bleed .b-promo_box-caption {
    padding: 15px 0;
  }
}
.b-promo_box.m-top-banner {
  color: #2F2F2F;
  line-height: 1.25;
}
.b-promo_box.m-top-banner .b-promo_box-picture {
  border-radius: 4px;
  max-height: 120px;
  padding-bottom: 0;
}
.b-promo_box.m-top-banner .b-promo_box-picture img {
  position: static;
}
.b-promo_box.m-top-banner .b-promo_box-caption {
  align-self: center;
  padding: 0;
}
.b-promo_box.m-top-banner .b-promo_caption-title {
  color: #2F2F2F;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.b-promo_box.m-top-banner .b-promo_caption-subtitle {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
}
@media screen and (min-width: 768px) {
  .b-promo_box.m-top-banner .b-promo_caption-subtitle {
    font-size: 15px;
  }
}
.b-promo_box.m-account {
  color: #FFFFFF;
}
.b-promo_box.m-account .b-promo_box-picture {
  border-radius: 4px;
  padding-bottom: 120px;
}
.b-promo_box.m-account .b-promo_box-caption {
  align-self: center;
  padding: 0;
}
.b-promo_box.m-account .b-promo_caption-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  color: #FFFFFF;
  margin-bottom: 0;
}
.b-promo_box.m-account .b-promo_caption-subtitle {
  font-size: 15px;
  font-weight: 60;
}
.b-promo_box.m-promo-banner {
  border-radius: 4px;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
}
.b-promo_box.m-promo-banner .b-promo_box-caption {
  padding: 20px;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .b-promo_box.m-promo-banner .b-promo_box-caption {
    padding: 20px 28px;
  }
}
.b-promo_box.m-promo-banner .b-promo_box-picture {
  border-radius: 4px;
  padding-bottom: 100px;
}
@media screen and (min-width: 1024px) {
  .b-promo_box.m-promo-banner .b-promo_box-picture {
    padding-bottom: 110px;
  }
}
.b-promo_box.m-promo-banner .b-promo_caption-title {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
}
@media screen and (min-width: 768px) {
  .b-promo_box.m-promo-banner .b-promo_caption-title {
    font-size: 24px;
  }
}
.b-promo_box.m-promo-banner .b-promo_caption-actions {
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  text-decoration: underline;
}
@media screen and (min-width: 1024px) {
  .b-promo_box.m-promo-banner .b-promo_caption-actions {
    font-size: 15px;
  }
}
.b-promo_box.m-storelocator .b-promo_box-picture {
  border-radius: 4px;
  padding-bottom: 352px;
}
@media screen and (max-width: 1023px) {
  .b-promo_box.m-storelocator .b-promo_box-caption {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 10px 0 15px;
  }
}
@media screen and (min-width: 1024px) {
  .b-promo_box.m-storelocator .b-promo_box-caption {
    align-self: start;
  }
}
.b-promo_box.m-storelocator .b-promo_caption-subtitle {
  font-size: 15px;
  font-weight: 600;
}
@media screen and (max-width: 1023px) {
  .b-promo_box.m-storelocator .b-promo_caption-subtitle {
    margin-bottom: 6px;
  }
}
.b-promo_box.m-storelocator .b-promo_caption-title {
  font-size: 24px;
}
@media screen and (min-width: 1024px) {
  .b-promo_box.m-storelocator .b-promo_caption-title {
    font-size: 32px;
  }
}
@media screen and (max-width: 1023px) {
  .b-promo_box.m-storelocator .b-promo_caption-actions {
    margin-top: auto;
    padding-top: 25px;
  }
}
.b-promo_box.m-confirmation .b-promo_box-picture {
  border-radius: 4px;
  padding-bottom: 120px;
}

/*md

# b-promo_caption

Promo caption is the content of promo components.

## Structure

`b-promo_caption` consist from 3 main elements:

* title
* subtitle
* actions container

All of this elements are optional.

## Variation

For sake of simpleness and robustness Boilerplate do not provide predefined sizes and styles
variations.

## Examples

### All elements

```html_example
<div class="b-promo_caption">
	<h2 class="b-promo_caption-title">
		Shop Now. Pay Later.
		Exclusively for Members
	</h2>
	<p class="b-promo_caption-subtitle">
		Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
	</p>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Activate
		</a>
	</div>
</div>
```

### Different order

```html_example
<div class="b-promo_caption">
	<p class="b-promo_caption-subtitle">
		Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
	</p>
	<h2 class="b-promo_caption-title">
		Shop Now. Pay Later.
		Exclusively for Members
	</h2>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Activate
		</a>
	</div>
</div>
```

### Only title and CTA

```html_example
<div class="b-promo_caption">
	<h2 class="b-promo_caption-title">
		Shop Now. Pay Later.
	</h2>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Join
		</a>
	</div>
</div>
```

### 2 CTA

```html_example
<div class="b-promo_caption">
	<h2 class="b-promo_caption-title">
		New spring collections
	</h2>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Men
		</a>
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Woman
		</a>
	</div>
</div>
```

### 3 CTA

```html_example
<div class="b-promo_caption">
	<h2 class="b-promo_caption-title">
		New spring collections
	</h2>
	<div class="b-promo_caption-actions">
		<a
			class="b-button m-outline"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Men
		</a>
		<a
			class="b-button"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Woman
		</a>
		<a
			class="b-button m-link"
			href="$url('Search-Show', 'cgid', 'category-2')$"
		>
			Kids
		</a>
	</div>
</div>
```

### Without CTA

```html_example
<a
	class="b-promo_caption"
	href="$url('Search-Show', 'cgid', 'category-2')$"
>
	<h2 class="b-promo_caption-title">
		New spring collections
	</h2>
	<p class="b-promo_caption-subtitle">
		Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
	</p>
</a>
```

## Example in component

### b-promo_info_box

```html_example
<div class="b-promo_info_box">
	<div class="b-promo_info_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Shop Now. Pay Later. <br/>
			Exclusively for Members
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a class="m-outline b-button" href="$url('Search-Show', 'cgid', 'category-2')$">
				Join today
			</a>
		</div>
	</div>
</div>
```

### b-promo-box

```html_example
<figure class="b-promo_box">
	<picture class="b-promo_box-picture">
		<source type="image/jpeg" media="(max-width: 767px)"
				srcset="../images/guide/examples/banner-16x9-sm.jpg?$staticlink$, ../images/guide/examples/banner-16x9-sm@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 768px) and (max-width: 1024px)"
				srcset="../images/guide/examples/banner-16x9-md.jpg?$staticlink$, ../images/guide/examples/banner-16x9-md@2x.jpg?$staticlink$ 2x" />
		<source type="image/jpeg" media="(min-width: 1025px)"
				srcset="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$, ../images/guide/examples/banner-16x9-lg@2x.jpg?$staticlink$2x" />
		<img
			loading="lazy"
			src="../images/guide/examples/banner-16x9-lg.jpg?$staticlink$"
			alt="Some dummy alternative text for image."
			width="1600"
			height="800"
		/>
	</picture>
	<figcaption class="b-promo_box-caption b-promo_caption">
		<h2 class="b-promo_caption-title">
			Explore More Possibilities
		</h2>
		<p class="b-promo_caption-subtitle">
			Join our Loyalty Program and try before you buy. Pay Later is available online and in-store with boilerplate app.
		</p>
		<div class="b-promo_caption-actions">
			<a
				class="b-button"
				href="$url('Search-Show', 'cgid', 'category-2')$"
			>
				Shop Now
			</a>
		</div>
	</figcaption>
</figure>
```

*/
.b-promo_caption {
  align-self: center;
}
.b-promo_caption-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
  color: #45132C;
  margin-bottom: 15px;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-promo_caption-title {
    font-size: 40px;
  }
}
@media screen and (min-width: 1367px) {
  .b-promo_caption-title {
    font-size: 48px;
  }
}
.b-promo_box.m-confirmation .b-promo_caption-title {
  font-size: 24px;
  margin-bottom: 0;
}
.b-promo_caption-subtitle {
  font-size: 20px;
  font-weight: 700;
}
@media screen and (min-width: 1024px) {
  .b-promo_caption-subtitle {
    font-size: 24px;
  }
}
.b-promo_box.m-confirmation .b-promo_caption-subtitle {
  font-size: 15px;
}
.b-promo_caption-description {
  font-size: 13px;
  max-width: 330px;
}
@media screen and (min-width: 1024px) {
  .b-promo_caption-description {
    font-size: 15px;
  }
}
.b-promo_caption-actions {
  display: grid;
  gap: 16px 8px;
  grid-auto-columns: max-content;
  grid-template-columns: repeat(var(--actions-columns), max-content);
  margin: 0 auto;
  max-width: var(--actions-width, auto);
}
.b-promo_caption-actions:not(:first-child) {
  margin-top: 25px;
}
.b-promo_caption-actions .b-button {
  white-space: normal;
  word-break: break-word;
}

.g-switcher {
  position: relative;
}
.g-switcher-label {
  background-color: #FFFFFF;
  border-radius: 22px;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 22px;
  position: relative;
  width: 40px;
}
.g-switcher-label::before {
  background-color: #45132C;
  border-radius: 50%;
  content: "";
  display: block;
  height: 18px;
  left: 2px;
  position: absolute;
  top: 2px;
  transition: left cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  width: 18px;
}
.g-switcher-input:checked + .g-switcher-label::before {
  left: 20px;
  transition: left cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}
.g-switcher-input {
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
}

.b-header_search {
  margin: 0 auto;
  max-width: 1418px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  text-align: center;
}
@media screen and (max-width: 1023px) {
  .b-header_search {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .b-header_search {
    padding-top: 30px;
  }
}
.b-header_search-title_no_results {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  text-align: left;
  word-break: break-word;
}
.b-header_search-no_products {
  margin-top: 12px;
}
.b-header_search-title {
  align-items: center;
  display: flex;
  justify-content: center;
}
.b-header_search-keywords {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
  color: #2F2F2F;
  display: inline-flex;
  quotes: "‘" "’";
}
@media screen and (max-width: 1023px) {
  .b-header_search-keywords {
    font-size: 15px;
  }
}
.b-header_search-keywords_count {
  font-size: 17px;
  font-weight: 700;
  line-height: 1.25;
  color: #757575;
  font-weight: 400;
  margin-left: 4px;
}
@media screen and (max-width: 1023px) {
  .b-header_search-keywords_count {
    font-size: 15px;
  }
}
.b-header_search-suggestion {
  font-size: 16px;
  margin-top: 16px;
  text-align: left;
}

.b-plp_actions {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
@media screen and (max-width: 1023px) {
  .b-plp_actions {
    background-color: #FFFFFF;
    margin: 0 -32px;
    padding: 20px 32px;
    position: sticky;
    top: 51px;
    z-index: 9;
  }
  .m-refinements_sticky .b-plp_actions {
    box-shadow: 0 4px 8px 0 hsla(0, 0%, 0%, 0.1), 0 1px 0 0 #D2D2D2 inset;
  }
  .b-header_slim .b-plp_actions {
    top: 81px;
  }
}
@media screen and (max-width: 767px) {
  .b-plp_actions {
    margin: 0 -15px;
    padding: 20px 15px;
  }
}
.m-hybrid .b-plp_actions {
  border-top: 1px solid #D2D2D2;
}
.b-plp_actions-top {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .b-plp_actions-top {
    display: none;
  }
}
.b-plp_actions-refinements_toggle {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 40px;
  justify-content: center;
  max-width: 100%;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: background-color, color, border;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;
  background: #45132C;
  border: 2px solid #45132C;
  border-radius: 4px;
  color: #FFFFFF;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: 0.03em;
  min-width: 97px;
  padding: 0 7px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-plp_actions-refinements_toggle {
    padding: 0 37px;
  }
}
@media screen and (min-width: 1024px) {
  .b-plp_actions-refinements_toggle {
    display: none;
  }
}
.b-plp_actions-refinements_toggle_svg {
  margin-right: 4px;
}
.b-plp_actions-sorting {
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  height: 40px;
  margin-left: auto;
  order: 2;
  padding-left: 5px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .b-plp_actions-sorting {
    flex: 0 0 auto;
    order: 3;
  }
}
.b-plp_actions-sorting_label {
  color: #2F2F2F;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4;
  margin-right: 10px;
  white-space: nowrap;
}
.b-plp_actions-results_count {
  align-items: center;
  color: #2F2F2F;
  display: flex;
  flex: 1 0 70%;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4;
  order: 3;
}
@media screen and (min-width: 768px) {
  .b-plp_actions-results_count {
    flex: 1 1 0;
    order: 2;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-plp_actions-results_count {
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .b-plp_actions-results_count {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .b-plp_actions-results_count.m-mobile {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .b-plp_actions-results_viewing {
    margin: 0 5px;
  }
}
.b-plp_actions-grid_toggle {
  flex: 0 1 auto;
  order: 4;
}
@media screen and (min-width: 768px) {
  .b-plp_actions-grid_toggle {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .b-plp_actions-grid_toggle {
    margin-left: auto;
  }
}
.b-plp_actions-grid_button {
  color: #ACACAC;
  cursor: pointer;
  float: left;
  line-height: 1;
  margin-left: 20px;
}
.b-plp_actions-grid_button.m-active {
  color: #45132C;
}

.b-plp_no_results {
  font-size: 16px;
  font-weight: 500;
}
.b-plp_no_results-actions {
  margin-top: 24px;
}
.b-plp_no_results-inner {
  margin: 40px auto 60px;
  max-width: 814px;
}
@media screen and (max-width: 767px) {
  .b-plp_no_results-inner {
    padding: 0 30px;
  }
}
.b-plp_no_results-title {
  color: #45132C;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 20px;
  text-align: center;
  z-index: 1;
}
.b-plp_no_results-subtitle {
  color: #2F2F2F;
  font-size: 15px;
  line-height: 22.5px;
}
.b-plp_no_results-description {
  color: #2F2F2F;
  font-size: 15px;
  line-height: 22.5px;
  margin: 40px auto 52px;
  max-width: 815px;
  text-align: center;
}

.b-search_noresults {
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 767px) {
  .b-search_noresults {
    margin-bottom: 45px;
  }
}
.b-search_noresults-bg {
  height: 202px;
  left: -25%;
  position: absolute;
  top: -6%;
  width: 202px;
}
@media screen and (min-width: 768px) {
  .b-search_noresults-bg {
    height: 329px;
    left: -9%;
    top: -14%;
    width: 329px;
  }
}
.b-search_noresults-bg path {
  fill: #EDB8E4;
}
.b-search_noresults-wrapper {
  align-items: center;
  background: #F5EEE4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 76px 28px 51px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .b-search_noresults-wrapper {
    padding: 56px 0;
  }
}
.b-search_noresults-header {
  line-height: 1.5;
  z-index: 2;
}
.b-search_noresults-title {
  color: #45132C;
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 18px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .b-search_noresults-title {
    font-size: 17px;
  }
}
.b-search_noresults-description {
  color: #2F2F2F;
  margin: 0 auto 20px;
  max-width: 251px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .b-search_noresults-description {
    max-width: 454px;
  }
}
.b-search_noresults-form {
  margin: 0 auto;
  max-width: 290px;
}
@media screen and (min-width: 768px) {
  .b-search_noresults-form {
    max-width: 425px;
  }
}
.b-search_noresults-form::after {
  display: none;
}
.b-search_noresults-recommendations {
  margin: auto;
  max-width: 566px;
  padding-bottom: 40px;
}
@media screen and (max-width: 767px) {
  .b-search_noresults-recommendations {
    padding: 0 45px;
  }
}
.b-search_noresults-products {
  margin-top: 41px;
}
.b-search_noresults-value {
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
}
.b-plp_no_results .b-search_noresults {
  overflow: visible;
}
.b-plp_no_results .b-search_noresults-bg {
  height: 197px;
  left: 0;
  top: 0;
  width: 197px;
}
@media screen and (max-width: 767px) {
  .b-plp_no_results .b-search_noresults-bg {
    left: -25%;
  }
}
.b-plp_no_results .b-search_noresults-wrapper {
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 767px) {
  .b-plp_no_results .b-search_noresults-wrapper {
    padding: 35px;
  }
}
.b-search_noresults .l-section {
  margin-top: 60px;
}

.b-applied_filters-items {
  display: flex;
  flex-wrap: wrap;
}
.b-applied_filters-item {
  align-items: center;
  background-color: #F2F2F2;
  border: 1px solid #ACACAC;
  border-radius: 2px;
  display: inline-flex;
  height: 31px;
  margin: 0 10px 10px 0;
  overflow: hidden;
  padding: 0 6px;
  user-select: none;
}
.b-applied_filters-item:hover {
  border-color: #C23D74;
}
.b-applied_filters-item.m-clear {
  background: none;
  border: none;
}
.b-applied_filters-clear_all {
  color: #2F2F2F;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  text-decoration: underline;
}
.b-applied_filters-clear_all:hover {
  color: #C23D74;
}
.b-applied_filters-value {
  align-items: center;
  display: inline-flex;
  line-height: 20px;
  margin-right: 8px;
  text-transform: capitalize;
}
.b-applied_filters-rating_icon {
  color: #C23D74;
  margin-left: 4px;
}
.b-applied_filters-remove_button {
  color: #757575;
  cursor: pointer;
}
.b-plp_no_results .b-applied_filters-title {
  display: none;
}
.b-plp_no_results .b-applied_filters-clear_all {
  color: #2F2F2F;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.3;
  text-decoration: underline;
}

@media screen and (min-width: 1024px) {
  .b-refinements_panel {
    padding-top: 20px;
  }
  .m-hybrid .b-refinements_panel {
    padding-top: 0;
  }
  .b-refinements_panel .b-slide_panel-action {
    display: none;
  }
}
.b-refinements_panel-title {
  color: #45132C;
  padding-bottom: 16px;
}
@media screen and (max-width: 1023px) {
  .b-refinements_panel-title {
    align-items: center;
    background-color: #F2F2F2;
    display: flex;
    min-height: 60px;
    padding: 0 16px;
  }
}
@media screen and (min-width: 1024px) {
  .b-refinements_panel-title button {
    display: none;
  }
}
.b-refinements_panel-title h2 {
  flex-grow: 1;
  font-size: 17px;
  font-weight: 600;
}
.b-refinements_panel-sticky_wrapper {
  height: 100%;
  position: relative;
}
.b-refinements_panel-sticky {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 60px);
  overflow-x: hidden;
  overflow-y: scroll;
  position: sticky;
  top: 60px;
}
@media screen and (min-width: 1024px) {
  .b-refinements_panel-sticky {
    max-height: calc(100vh - 101px);
    top: 101px;
  }
  .b-refinements_panel-sticky::-webkit-scrollbar {
    background-color: #FFFFFF;
    width: 5px;
  }
  .b-refinements_panel-sticky::-webkit-scrollbar-thumb {
    background-color: #D2D2D2;
    border-radius: 8px;
  }
}
.b-refinements_panel.m-busy {
  cursor: wait;
  pointer-events: none;
}
.b-refinements_panel.m-busy .b-refinements_panel-content {
  opacity: 0.6;
}

.b-slide_panel {
  color: #45132C;
  display: flex;
  height: 100%;
  transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
}
.b-slide_panel.m-active_level_1 {
  transform: translateX(0);
}
.b-slide_panel.m-active_level_2 {
  transform: translateX(-100%);
}
.b-slide_panel-container {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  overflow-y: auto;
}
.b-slide_panel-submenu {
  height: calc(100% - 60px);
}
.b-slide_panel-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.b-slide_panel-container.m-level_1 .b-slide_panel-content {
  display: none;
}
.b-slide_panel-header {
  align-items: center;
  background-color: #F2F2F2;
  display: flex;
  min-height: 60px;
  padding: 0 16px;
}
.b-slide_panel-header_action {
  width: 100%;
}
.b-slide_panel-clear {
  color: #757575;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  justify-content: center;
  text-align: center;
  text-decoration: underline;
  width: 100%;
}
.b-slide_panel-title {
  font-size: 17px;
  font-weight: 600;
  padding: 0 15px;
  text-transform: capitalize;
}
.b-slide_panel.m-active_level_1 .b-slide_panel-title {
  padding-left: 0;
}
.b-slide_panel-back, .b-slide_panel-close_button {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  text-align: center;
  width: 32px;
  height: 22px;
  width: 22px;
}
.b-slide_panel-item {
  align-items: center;
  box-shadow: 0 1px 0 0 #D2D2D2;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 13px 15px;
}
.b-slide_panel-item_icon {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  text-align: center;
  width: 32px;
  height: 15px;
  width: 15px;
}
.b-slide_panel-name {
  font-size: 15px;
  text-transform: capitalize;
}
.b-slide_panel-name.m-idea {
  text-transform: initial;
}
.b-slide_panel-action {
  background-color: #FFFFFF;
  bottom: 0;
  box-shadow: 0 -4px 16px 0 rgba(0, 0, 0, 0.15);
  margin-top: auto;
  padding: 0 15px;
  position: sticky;
}
.b-slide_panel .b-ideas_toggle, .b-slide_panel-clear, .b-slide_panel-close, .b-slide_panel-apply {
  display: flex;
  margin: 15px 0;
}

.b-refinements_accordion-item {
  border-top: 1px solid #D2D2D2;
}
.b-refinements_accordion-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  justify-content: space-between;
  padding: 12px 15px;
  text-align: left;
  width: 100%;
}
.b-refinements_accordion-title {
  color: #45132C;
  font-size: 15px;
  text-transform: capitalize;
}
@media screen and (min-width: 1024px) {
  .b-refinements_accordion-title {
    font-size: 13px;
  }
}
.b-refinements_accordion-title_selections {
  color: #45132C;
  font-size: 14px;
}
@media screen and (min-width: 1024px) {
  .b-refinements_accordion-title_selections {
    display: none;
  }
}
.b-refinements_accordion-title.m-idea {
  text-transform: initial;
}
.b-refinements_accordion-content {
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: relative;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  transition-property: height, opacity;
  visibility: hidden;
  height: 0;
}
.b-refinements_accordion-content[aria-hidden=false] {
  height: auto;
  opacity: 1;
  visibility: visible;
  height: max-content;
}
.b-refinements_accordion-item:not([data-initialized="1"]) .b-refinements_accordion-content {
  height: auto;
  opacity: 1;
  visibility: visible;
}
.b-refinements_accordion-content_inner {
  overflow: hidden;
  padding: 0 15px 20px;
}
@media screen and (min-width: 1024px) {
  .b-refinements_accordion-button, .b-refinements_accordion-content_inner {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.b-refinement-view_more {
  color: #45132C;
  font-size: 13px;
  font-weight: 600;
  text-decoration: underline;
}

.b-refinement_list {
  overflow: hidden;
}
.b-refinement_list-item {
  padding: 8px 0;
}
.b-slide_panel .b-refinement_list {
  overflow: auto;
  padding: 5px 20px;
}
.b-slide_panel .b-refinement_list-item {
  padding: 12px 0;
}

/*md

# Refinement link

TBD

*/
.b-refinement_link {
  color: #000000;
  cursor: pointer;
  padding-left: 12px;
  text-decoration: none;
}
.b-refinement_link:hover {
  color: rgba(194, 61, 116, 0.8);
  text-decoration: none;
}
.b-refinement_link.m-selected {
  font-weight: 700;
  padding-left: 0;
  pointer-events: none;
}
.b-refinement_link.m-return {
  align-items: center;
  display: flex;
  font-weight: 700;
  padding-left: 0;
}
.b-refinement_link.m-return svg {
  margin-right: 4px;
  transform: rotate(90deg);
}

/*md

# Refinement radio

TBD

*/
.b-refinement_radio {
  align-items: center;
  display: flex;
  position: relative;
  user-select: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
}
.b-refinement_radio:hover {
  text-decoration: none;
}
.b-refinement_radio[aria-disabled=true], .b-refinement_radio[aria-checked=true], .b-refinement_radio.m-checked {
  pointer-events: none;
}
.b-refinement_radio-button {
  display: none;
}
.b-refinement_radio-icon {
  appearance: none;
  background: transparent;
  border: 1px solid #ACACAC;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  height: 15px;
  margin-right: 8px;
  position: relative;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: border, box-shadow;
  vertical-align: sub;
  width: 15px;
}
.b-refinement_radio-icon::-ms-check {
  display: none;
}
.b-refinement_radio:hover .b-refinement_radio-icon {
  border-color: #C23D74;
}
.b-refinement_radio[aria-checked=true] .b-refinement_radio-icon, .b-refinement_radio.m-checked .b-refinement_radio-icon {
  border-color: #45132C;
  border-width: 4px;
}
.b-refinement_radio[aria-checked=true] .b-refinement_radio-icon::before, .b-refinement_radio.m-checked .b-refinement_radio-icon::before {
  transform: translate(-50%, -50%) scale(1);
}
.b-refinement_radio[aria-disabled=true] .b-refinement_radio-icon {
  background-color: #F2F2F2;
  border-color: #D2D2D2;
  cursor: default;
  transform: scale(1);
}
.b-refinement_radio[aria-disabled=true] .b-refinement_radio-icon::before {
  background-color: #757575;
}

/*md

# Refinement checkbox

TBD

*/
.b-refinement_checkbox {
  align-items: flex-start;
  display: flex;
  font-size: 13px;
  line-height: 18px;
  position: relative;
  user-select: none;
  cursor: pointer;
  /* stylelint-disable */
  /* stylelint-enable */
}
.b-refinement_checkbox:hover {
  text-decoration: none;
}
.b-refinement_checkbox[aria-disabled=true] {
  opacity: 0.6;
  pointer-events: none;
}
.b-refinement_checkbox-icon {
  background-color: #FFFFFF;
  border: 1px solid #ACACAC;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 8px;
  min-width: 16px;
  position: relative;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: border-color;
  flex: 0 0 auto;
}
.b-refinement_checkbox-icon path {
  transform: scale(0);
  transform-origin: center center;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: transform;
}
@media not all and (pointer: coarse) {
  .b-refinement_checkbox-icon .b-refinement_checkbox:hover .b-refinement_checkbox-icon .b-refinement_checkbox {
    border-color: #45132C;
  }
}
.b-refinement_checkbox[aria-checked=true] .b-refinement_checkbox-icon {
  background-color: #45132C;
  border: 1px solid #45132C;
}
.b-refinement_checkbox[aria-checked=true] .b-refinement_checkbox-icon path {
  color: #FFFFFF;
  transform: scale(1);
}
.b-refinement_checkbox[aria-disabled=true] .b-refinement_checkbox-icon {
  background-color: #F2F2F2;
  border-color: #D2D2D2;
  cursor: default;
  transform: scale(1);
}
.b-refinement_checkbox[aria-disabled=true] .b-refinement_checkbox-icon path {
  stroke: #757575;
}
.b-refinement_checkbox.m-swatch .b-refinement_checkbox-icon_bg {
  color: #FFFFFF;
  fill: none;
}
.b-refinement_checkbox.-loading .b-refinement_checkbox-icon {
  animation: rotate 0.75s linear infinite;
  background: transparent !important;
  border-color: #ACACAC;
  border-radius: 100%;
  border-style: inset;
}
.b-refinement_checkbox.-loading .b-refinement_checkbox-icon_bg {
  fill: none !important;
}
.b-refinement_checkbox.m-swatch[aria-checked=true] .b-refinement_checkbox-icon_bg {
  fill: #45132C;
}

.b-refinement_option-label {
  align-items: center;
  color: #2F2F2F;
  flex: 1 1 0;
  font-size: 13px;
}
.b-refinement_option-count {
  color: #757575;
  line-height: 1;
  margin-left: 4px;
}

.b-search_promo {
  margin: 10px auto 0;
  width: 100%;
}
@media screen and (min-width: 1367px) {
  .b-search_promo {
    max-width: 1418px;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.b-content_item-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
}
.b-content_item-description {
  font-weight: 300;
  margin-bottom: 16px;
}
.b-content_item-link {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
}
@media not all and (pointer: coarse) {
  .b-content_item-link:hover {
    color: #C23D74;
  }
}

.b-search_tips {
  margin-top: 41px;
}
.b-search_tips-title {
  color: #2F2F2F;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  margin: 16px 0;
}
@media screen and (max-width: 767px) {
  .b-search_tips-title {
    margin-bottom: 10px;
  }
}
.b-search_tips-list {
  color: #2F2F2F;
  font-size: 13px;
  line-height: 1.45;
  list-style: none;
}
.b-search_tips-item {
  margin-bottom: 8px;
  margin-left: 20px;
  padding-left: 8px;
  position: relative;
}
.b-search_tips-item::before {
  background-color: #45132C;
  border-radius: 50%;
  content: "";
  height: 6px;
  left: -8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
}
@media screen and (max-width: 767px) {
  .b-search_tips-item::before {
    top: 6px;
    transform: translateY(0);
  }
}

.b-customer_service {
  margin-top: 24px;
}
.b-customer_service-title {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
}
.b-customer_service-list {
  font-weight: 500;
  list-style: none;
  padding: 0;
}
.b-customer_service-item {
  color: #2F2F2F;
  font-size: 13px;
  line-height: 1.45;
  margin-bottom: 20px;
}
.b-customer_service-item:last-child {
  margin-bottom: 0;
}
.b-customer_service-link {
  text-decoration: none;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
.b-customer_service-link:hover {
  color: #C23D74;
}
.b-customer_service-text {
  font-size: 13px;
  line-height: 18px;
  margin-top: 16px;
}

.b-category_link {
  display: flex;
  gap: 16px;
  margin-top: 20px;
}
@media screen and (min-width: 1367px) {
  .b-category_link {
    margin-top: 23px;
    max-width: 1360px;
  }
}
@media screen and (max-width: 767px) {
  .b-category_link {
    margin-top: 11px;
  }
}
.b-category_link-item {
  line-height: 15px;
  white-space: nowrap;
}
.b-category_link-carousel .b-carousel-ctrl {
  margin-top: 0;
}
.b-category_link-carousel .b-carousel-ctrl.m-prev {
  left: -15px;
}
.b-category_link-carousel .b-carousel-ctrl.m-next {
  right: -15px;
}

.b-plp_hybrid .b-product_tile {
  border-width: 0 0 1px;
  box-shadow: none;
  display: flex;
  padding-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .b-plp_hybrid .b-product_tile {
    display: block;
    padding-bottom: 10px;
  }
}
.b-plp_hybrid .b-product_tile-top {
  flex: 1 0 auto;
}
.b-plp_hybrid .b-product_tile-info {
  display: flex;
  flex-direction: column;
  min-width: 325px;
  padding: 0 0 0 10px;
  width: 325px;
}
@media screen and (max-width: 767px) {
  .b-plp_hybrid .b-product_tile-info {
    padding: 10px 0 0;
    width: auto;
  }
}
.b-plp_hybrid .b-product_tile-info.m-single-image {
  min-width: none;
  width: auto;
}
.b-plp_hybrid .b-product_tile-bottom {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 5px;
  position: static;
}
.b-plp_hybrid .b-product_tile-cta {
  border-radius: 20px;
  margin-top: 15px;
}
@media screen and (min-width: 1367px) {
  .b-plp_hybrid .b-product_tile-bottom.m-single-image .b-product_tile-cta {
    max-width: 315px;
  }
}
@media screen and (min-width: 1367px) {
  .b-plp_hybrid .b-product_tile-bottom.m-single-image .b-product_details-full {
    bottom: 23px;
    max-width: none;
    position: absolute;
    right: 0;
  }
}
.b-plp_hybrid .b-product_tile-link {
  display: block;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 10px;
  max-width: 460px;
}
@media screen and (max-width: 767px) {
  .b-plp_hybrid .b-product_tile-link {
    font-size: 15px;
  }
}
.b-plp_hybrid .b-product_tile-price {
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .b-plp_hybrid .b-product_tile-price {
    font-size: 13px;
    font-weight: 600;
  }
}
.b-plp_hybrid .b-product_details-quantity_and_link {
  display: flex;
  justify-content: space-between;
}
.b-plp_hybrid .b-product_details-quantity_label {
  font-size: 15px;
  padding: 5px 0;
}
@media screen and (max-width: 767px) {
  .b-plp_hybrid .b-product_details-quantity_label {
    font-size: 13px;
  }
}
.b-plp_hybrid .b-product_details-full {
  align-items: center;
  display: flex;
  float: right;
  font-size: 17px;
  line-height: 17px;
  margin-top: 35px;
  max-width: 130px;
  text-align: right;
}
@media screen and (max-width: 767px) {
  .b-plp_hybrid .b-product_details-full {
    font-size: 13px;
    max-width: 170px;
  }
  .b-plp_hybrid .b-product_details-full svg {
    width: 14px;
  }
}
.b-plp_hybrid .b-product_details-fulllink {
  font-weight: 600;
  text-decoration: underline;
}
.b-plp_hybrid .b-stepper-button {
  border-width: 2px;
}
.b-plp_hybrid .b-stepper-input {
  border-width: 2px 0;
}
.b-plp_hybrid .b-wishlist_button {
  display: none;
}

.b-ideas_toggle {
  background-color: #C23D74;
  border-radius: 2px;
  display: inline-flex;
  justify-content: space-evenly;
  padding: 12px 5px 11px;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .b-refinements_panel .b-ideas_toggle {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1023px) {
  .b-wishlist.m-guest .b-ideas_toggle-wrapper {
    order: 1;
  }
}
.b-ideas_toggle-container {
  align-items: center;
  display: flex;
}
.b-ideas_toggle-title {
  align-items: center;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 13px;
  font-weight: 500;
  justify-content: center;
  line-height: 16px;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}
@media not all and (pointer: coarse) {
  .b-ideas_toggle-title:hover {
    opacity: 0.8;
  }
}
.b-ideas_toggle-title.m-active {
  pointer-events: none;
}
.b-ideas_toggle-label {
  display: flex;
  flex-basis: 50%;
  flex-wrap: wrap;
  justify-content: center;
}

.b-global_slot {
  display: none;
}
.b-global_slot:first-child {
  display: block;
}

.b-plp_grid_slot {
  display: flex;
  flex-direction: column;
  grid-column: span 2;
  justify-content: space-between;
  position: relative;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}
.b-plp_grid_slot::before, .b-plp_grid_slot::after {
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
.b-plp_grid_slot::before {
  background-color: #FFFFFF;
}
.b-plp_grid_slot::after {
  animation: loader 1.1s infinite ease;
  border-radius: 50%;
  display: block;
  font-size: 10px;
  height: 1em;
  margin: auto;
  transform: translateZ(0);
  width: 1em;
}
.b-plp_grid_slot[aria-busy=true] {
  cursor: wait;
  pointer-events: none;
}
.b-plp_grid_slot[aria-busy=true]::before, .b-plp_grid_slot[aria-busy=true]::after {
  opacity: 1;
}
@media screen and (max-width: 767px) {
  .b-plp_grid_slot {
    min-height: 350px;
  }
  .l-plp_grid.m-one_column .b-plp_grid_slot {
    grid-column: span 1;
  }
}
.b-plp_grid_slot::before {
  opacity: 0.95;
  z-index: 2;
}
.b-plp_grid_slot::after {
  opacity: 1;
  z-index: 2;
}
.b-plp_grid_slot.m-single {
  grid-column: span 1;
  grid-column-end: 5;
}
@media screen and (max-width: 767px) {
  .b-plp_grid_slot.m-single {
    grid-column-end: 3;
  }
}
.b-plp_grid_slot.m-grey {
  background-color: #D2D2D2;
}
.b-plp_grid_slot.m-inited::after, .b-plp_grid_slot.m-inited::before {
  display: none;
}
.b-plp_grid_slot-btn {
  background-color: #45132C;
  border-color: #45132C;
  color: #FFFFFF;
  margin: 0 20px 20px auto;
  max-width: 180px;
  padding: 0 30px;
  z-index: 1;
}
.b-plp_grid_slot-btn:hover {
  background-color: #230A16;
}
.b-plp_grid_slot-btn.m-outline {
  background-color: #45132C;
  border-color: #45132C;
  color: #FFFFFF;
}
.b-plp_grid_slot-btn.m-outline:hover {
  background-color: #230A16;
}
.b-plp_grid_slot.m-single .b-plp_grid_slot-btn {
  margin: 0;
  max-width: none;
  padding: 0;
}
.b-plp_grid_slot.m-single .b-plp_grid_slot-btn.b-button {
  display: none;
}
.b-plp_grid_slot.m-dark .b-plp_grid_slot-btn {
  background-color: #F5EEE4;
  border-color: #F5EEE4;
  color: #45132C;
}
.b-plp_grid_slot.m-dark .b-plp_grid_slot-btn:hover {
  background-color: #45132C;
  border-color: #45132C;
  color: #F5EEE4;
}
.b-plp_grid_slot.m-dark .b-plp_grid_slot-btn.m-outline {
  background-color: #F5EEE4;
  border-color: #F5EEE4;
  color: #45132C;
}
.b-plp_grid_slot.m-dark .b-plp_grid_slot-btn.m-outline:hover {
  background-color: #45132C;
  border-color: #45132C;
  color: #F5EEE4;
}
.b-plp_grid_slot-content {
  background-color: rgba(245, 238, 228, 0.75);
  border-radius: 0 20px 20px 0;
  color: #45132C;
  left: 0;
  margin: 0 !important;
  max-height: 181px;
  max-width: 266px;
  padding: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.b-plp_grid_slot.m-dark .b-plp_grid_slot-content {
  background-color: rgba(69, 19, 44, 0.75);
  color: #F5EEE4;
}
.b-plp_grid_slot.m-single .b-plp_grid_slot-content {
  background: none;
  max-width: none;
  position: static;
  text-align: center;
  transform: none;
  width: 100%;
}
.b-plp_grid_slot-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
  color: #45132C !important;
  display: inline;
  line-height: 1.2;
  max-width: none !important;
}
.m-inited .b-plp_grid_slot-title {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.b-plp_grid_slot.m-single .b-plp_grid_slot-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
  background-color: rgba(245, 238, 228, 0.75);
  border-radius: 20px 20px 2px 2px;
  bottom: 0;
  color: #45132C !important;
  left: 0;
  max-height: 86px;
  padding: 8px;
  position: absolute;
  text-align: center;
  top: auto;
  width: 100%;
  z-index: 1;
}
.b-plp_grid_slot.m-dark .b-plp_grid_slot-title {
  color: #F5EEE4 !important;
}
.b-plp_grid_slot.m-single.m-dark .b-plp_grid_slot-title {
  background-color: rgba(69, 19, 44, 0.75);
}
.b-plp_grid_slot-subtitle {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}
.b-plp_grid_slot-img {
  border-radius: 2px;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}
.b-plp_grid_slot-img .g-image {
  min-width: 100%;
}
.b-plp_grid_slot .g-badge {
  display: none;
  font-size: 18px !important;
  line-height: 1.25 !important;
  margin: 0 0 3px !important;
  max-width: none;
  position: static;
  white-space: nowrap;
}
.b-plp_grid_slot.m-inited .g-badge {
  display: inline-block;
}
.b-plp_grid_slot.m-single .g-badge {
  font-size: 13px !important;
  left: 0;
  margin: 6px !important;
  padding: 4px 8px 3.75px;
  position: absolute;
  top: 0;
  z-index: 1;
}

@media screen and (min-width: 1024px) {
  .b-idea_dialog-main {
    padding-top: 15px;
  }
}
.b-idea_dialog-top {
  margin-bottom: 20px;
}
@media screen and (min-width: 1024px) {
  .b-idea_dialog-top {
    display: flex;
    margin-bottom: 20px;
  }
}
.b-idea_dialog-image {
  margin: 0 -16px 15px;
}
@media screen and (min-width: 768px) {
  .b-idea_dialog-image {
    margin: 0 -20px 18px;
  }
}
@media screen and (min-width: 1024px) {
  .b-idea_dialog-image {
    margin: 0 30px 0 -20px;
    min-width: 316px;
  }
}
.b-idea_dialog-details {
  position: relative;
}
.b-idea_dialog-bottom {
  display: flex;
  margin-top: 15px;
}
@media screen and (max-width: 767px) {
  .b-idea_dialog-bottom {
    background-color: rgba(255, 255, 255, 0.8);
    bottom: calc(100% + 15px);
    margin: 0 -16px;
    padding: 14px 17px;
    position: absolute;
    width: calc(100% + 32px);
  }
}
.b-idea_dialog-bottom .b-button {
  text-transform: capitalize;
}
.b-idea_dialog .b-product_details-name {
  color: #45132C;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 16px;
}
@media screen and (max-width: 767px) {
  .b-idea_dialog .b-product_details-name {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 42px;
  }
}
@media screen and (min-width: 1024px) {
  .b-idea_dialog .b-product_details-name {
    font-size: 20px;
  }
}
.b-idea_dialog .b-product_details-share {
  margin-right: 15px;
}
@media screen and (min-width: 1024px) {
  .b-idea_dialog .b-product_details-share {
    min-width: 130px;
  }
}
@media screen and (max-width: 767px) {
  .b-idea_dialog .b-product_details-description {
    font-size: 13px;
  }
}
.b-idea_dialog .b-product_details-attributes {
  margin-bottom: 15px;
}
@media screen and (max-width: 767px) {
  .b-idea_dialog .b-product_details-attribute_label {
    font-size: 11px;
    margin-bottom: 0;
  }
}
.b-idea_dialog .b-product_details-attribute_icon {
  color: #45132C;
}
.b-idea_dialog .b-product_details-attribute_icon svg {
  height: 13px;
  width: 13px;
}
@media screen and (min-width: 1024px) {
  .b-idea_dialog .b-product_details-attribute_icon svg {
    height: 16px;
    width: 16px;
  }
}
.b-idea_dialog .b-product_slider-ctrl[disabled] {
  visibility: hidden;
}
.b-idea_dialog .b-product_share {
  font-size: 15px;
  left: 0;
  margin-bottom: 12px;
  min-width: 214px;
  right: auto;
}
.b-idea_dialog .b-product_share .b-wishlist_button.m-simple {
  font-size: 15px;
}
.b-idea_dialog ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.b-age_restricted-badge {
  background-color: #A42015;
  border-radius: 4px;
  color: #FFFFFF;
  display: inline-block;
  font-size: 11px;
  grid-area: age;
  padding: 2px 4px 2px 2px;
  position: relative;
}
.b-cart_product-item .b-age_restricted-badge {
  align-self: start;
  margin-top: 10px;
  max-width: 110px;
}
.b-order_product .b-age_restricted-badge {
  margin-top: 15px;
}
.b-age_restricted-icon {
  cursor: pointer;
}
.b-age_restricted-message {
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
  color: #2F2F2F;
  font-weight: 600;
  grid-area: message;
  left: 0;
  margin: 15px 0;
  max-width: 90vw;
  padding: 10px;
  position: absolute;
  text-align: left;
  width: 483px;
  z-index: 1;
}
.b-age_restricted-message::before {
  border: 10px solid #FFFFFF;
  border-color: transparent transparent #FFFFFF #FFFFFF;
  box-shadow: -3px 3px 5px 0 rgba(0, 0, 0, 0.1);
  content: "";
  left: 5px;
  position: absolute;
  top: -10px;
  transform: rotate(135deg);
}
@media screen and (max-width: 767px) {
  .b-cart_quickview-inner .b-age_restricted-message, .b-order_product.m-confirmation .b-age_restricted-message {
    max-width: 315px;
    transform: translateX(-30%);
  }
  .b-cart_quickview-inner .b-age_restricted-message::before, .b-order_product.m-confirmation .b-age_restricted-message::before {
    left: 30%;
  }
}
.b-age_restricted-icon:focus + .b-age_restricted-message {
  display: block;
}

.b-product_gallery {
  position: relative;
  /* stylelint-disable-next-line no-descending-specificity */
}
.b-product_gallery-inner {
  display: flex;
}
@media screen and (max-width: 1023px) {
  .b-product_gallery-inner {
    flex-direction: column-reverse;
  }
}
@media screen and (min-width: 1024px) {
  .m-alternative-gallery .b-product_gallery-inner {
    align-items: flex-start;
  }
}
.b-product_gallery-image_wrap {
  display: block;
}
.b-product_gallery-zoom {
  color: #F2F2F2;
  font-size: 15px;
  font-weight: 500;
  position: absolute;
  text-align: center;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .b-product_gallery-zoom {
    bottom: 5px;
    right: 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-product_gallery-zoom {
    bottom: 20px;
    right: 20px;
  }
}
@media screen and (max-width: 1023px) {
  .l-pdp.m-idea .b-product_gallery-zoom {
    bottom: auto;
    right: 6px;
    top: 6px;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_gallery-zoom {
    background-color: rgba(242, 242, 242, 0.8);
    border-radius: 40px;
    color: #2F2F2F;
    left: 50%;
    margin-left: -60px;
    margin-top: -20px;
    opacity: 0;
    padding: 5px 14px;
    top: 50%;
    transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  }
  .b-product_slider-item:hover .b-product_gallery-zoom {
    opacity: 1;
  }
}
.b-product_gallery-thumbs {
  bottom: 0;
  height: 696px;
  left: 40px;
  margin: auto 0;
  max-height: 100vh;
  min-width: 118px;
  overflow: hidden;
  padding: 1px 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  visibility: hidden;
  z-index: 12;
}
@media screen and (max-width: 1023px) {
  .b-product_gallery-thumbs {
    display: none;
  }
}
.b-product_gallery-thumbs.m-zoomed-in {
  pointer-events: initial;
  visibility: visible;
}
.m-alternative-gallery .b-product_gallery-thumbs:not(.m-zoomed-in) {
  bottom: auto;
  left: auto;
  margin: 0 20px 0 0;
  pointer-events: all;
  position: relative;
  visibility: visible;
  z-index: 1;
}
.b-product_gallery-thumbs_track {
  display: flex;
  overflow: hidden;
  overflow-behavior: contain;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-x: none;
  overflow-scrolling: touch;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  -ms-scroll-chaining: none;
  scrollbar-width: none;
  flex-direction: column;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  height: 532px;
  max-height: calc(100vh - 164px);
}
.b-product_gallery-thumbs_track::-webkit-scrollbar {
  display: none;
}
.b-product_gallery-thumb {
  background: #F2F2F2;
  cursor: pointer;
  height: 118px;
  min-height: 118px;
  min-width: 118px;
  position: relative;
  scroll-snap-align: center;
  transform: translateZ(0);
  width: 118px;
}
.b-product_gallery-thumb::after {
  border: 2px solid transparent;
  border-radius: 4px;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: box-shadow, border;
  z-index: 1;
}
.b-product_gallery-thumb.m-current::after {
  border-color: #45132C;
}
.m-alternative-gallery .b-product_gallery-thumb {
  background: none;
  border: 1px solid #D2D2D2;
  border-radius: 4px;
}
.b-product_gallery-thumb + .b-product_gallery-thumb {
  margin-top: 20px;
}
.m-alternative-gallery .b-product_gallery-thumb + .b-product_gallery-thumb {
  margin-top: 8px;
}
.b-product_gallery-thumbs_ctrl {
  border: none;
  height: 82px;
  left: 0;
  line-height: 82px;
  opacity: 0;
  position: relative;
  right: 0;
  width: 100%;
  z-index: 1;
}
.b-product_gallery-thumbs_ctrl::before {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #D2D2D2;
  border-radius: 100%;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
  content: "";
  height: 40px;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  width: 40px;
}
.m-alternative-gallery .b-product_gallery-thumbs_ctrl {
  display: none;
}
.b-product_gallery-thumbs.m-inited .b-product_gallery-thumbs_ctrl:not([disabled]) {
  cursor: pointer;
  opacity: 1;
}
.b-product_gallery-thumbs.m-inited .b-product_gallery-thumbs_ctrl:not([disabled]):hover {
  color: #C23D74;
}
.b-product_gallery-thumbs_ctrl.m-next {
  bottom: 0;
}
.b-product_gallery-thumbs_ctrl.m-next svg {
  transform: rotate(-90deg);
}
.b-product_gallery-thumbs_ctrl.m-prev {
  top: 0;
}
.b-product_gallery-thumbs_ctrl.m-prev svg {
  transform: rotate(90deg);
}
.b-product_gallery-thumbs.m-zoomed-in .b-product_gallery-thumbs_ctrl {
  display: block;
}
.b-product_gallery-image {
  bottom: 0;
  color: #F2F2F2;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.m-alternative-gallery .b-product_gallery-image {
  height: calc(100% - 8px);
  inset: 4px;
  width: calc(100% - 8px);
}
.b-product_gallery-main {
  width: 100%;
}

.b-product_video {
  overflow: hidden;
  position: relative;
}
.b-product_video::before {
  content: "";
  float: left;
  padding-bottom: 56.25%;
}
.b-product_video-player, .b-product_video-cover {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-product_video-image {
  border-radius: 4px;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .b-zoom_info {
    align-items: center;
    display: flex;
    pointer-events: none;
  }
}
@media screen and (max-width: 1023px) {
  .b-photoswipe .b-zoom_info {
    background-color: rgba(242, 242, 242, 0.8);
    border-radius: 40px;
    font-size: 15px;
    font-weight: 500;
    left: 50%;
    margin-top: -20px;
    padding: 10px 20px;
    position: absolute;
    top: 50%;
    transform: translate(-50%);
    z-index: 11;
  }
  .b-photoswipe.pswp--zoomed .b-zoom_info, .b-photoswipe.pswp--html .b-zoom_info, .b-photoswipe.pswp--zoomed-in .b-zoom_info {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .b-zoom_info-icon {
    margin-right: 4px;
  }
}
@media screen and (min-width: 1024px) {
  .b-zoom_info-icon svg {
    height: 28px;
    width: 28px;
  }
}
@media screen and (max-width: 1023px) {
  .b-zoom_info-content {
    display: none;
  }
}

.b-photoswipe-close {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  text-align: center;
  width: 32px;
  height: 20px;
  margin-left: auto;
  z-index: 1;
}
@media screen and (max-width: 1023px) {
  .b-photoswipe-close {
    margin-right: 10px;
  }
}
.b-photoswipe-close svg {
  height: 22px;
  width: 22px;
}
.b-photoswipe-info {
  display: flex;
  justify-content: space-between;
  margin: 15px 15px 0 0;
  z-index: 1;
}
@media screen and (min-width: 1024px) {
  .b-photoswipe-info {
    margin: 40px 40px 0 0;
  }
  .b-photoswipe-info .b-zoom_info {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .b-photoswipe-info {
    width: 100%;
  }
  .b-photoswipe-info .b-zoom_info-icon {
    display: none;
  }
}
.b-photoswipe.pswp--zoomed-in {
  z-index: 13;
}

.pswp {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 11;
  -webkit-text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  outline: 0;
}

.pswp img {
  max-width: none;
}

.pswp--animate_opacity {
  opacity: 0.001;
  will-change: opacity;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}

.pswp--open {
  display: block;
}

.pswp--zoom-allowed .pswp__img {
  cursor: url("./images/zoom-in.png"), zoom-in;
}

.pswp--zoomed-in .pswp__img {
  cursor: url("./images/zoom-out.png"), zoom-out;
}

.pswp--dragging .pswp__img {
  cursor: grabbing;
}

.pswp__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  opacity: 0;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  will-change: opacity;
}

.pswp__scroll-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  .pswp__scroll-wrap {
    left: 180px;
    right: 25px;
    transition: overflow 0s 1s linear;
  }
  .pswp--zoomed-in .pswp__scroll-wrap {
    overflow: visible;
    transition-delay: 0s;
  }
}

.pswp__container,
.pswp__zoom-wrap {
  -ms-touch-action: none;
  touch-action: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.pswp__container,
.pswp__img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.pswp__zoom-wrap {
  position: absolute;
  width: 100%;
  transform-origin: left top;
  transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}

.pswp__bg {
  will-change: opacity;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}

.pswp--animated-in .pswp__bg,
.pswp--animated-in .pswp__zoom-wrap {
  -webkit-transition: none;
  transition: none;
}

.pswp__container,
.pswp__zoom-wrap {
  -webkit-backface-visibility: hidden;
}

.pswp__item {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  .pswp__item {
    overflow: visible;
  }
}

.pswp__img {
  border-radius: 4px;
  position: absolute;
  width: auto;
  height: auto;
  top: 0;
  left: 0;
}

.pswp__img--placeholder {
  -webkit-backface-visibility: hidden;
}

.pswp__img--placeholder--blank {
  background: #F2F2F2;
}

.pswp--ie .pswp__img {
  width: 100% !important;
  height: auto !important;
  left: 0;
  top: 0;
}

.pswp__error-msg {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  margin-top: -8px;
  color: #A42015;
}

.pswp__error-msg a {
  color: #A42015;
  text-decoration: underline;
}

.pswp__counter {
  display: none;
}

.pswp__button--arrow--left,
.pswp__button--arrow--right {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
  border: solid 1px #D2D2D2;
  border-radius: 100%;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  position: absolute;
}
@media screen and (min-width: 1024px) {
  .pswp__button--arrow--left,
  .pswp__button--arrow--right {
    display: none;
  }
}
.pswp__button--arrow--left .b-icon_chevron,
.pswp__button--arrow--right .b-icon_chevron {
  margin: auto;
}

.pswp__button--arrow--left {
  left: 5px;
}
.pswp--first .pswp__button--arrow--left {
  display: none;
}
.pswp__button--arrow--left .b-icon_chevron {
  transform: rotate(90deg);
}

.pswp__button--arrow--right {
  right: 5px;
}
.pswp--last .pswp__button--arrow--right {
  display: none;
}
.pswp__button--arrow--right .b-icon_chevron {
  transform: rotate(-90deg);
}

.pswp__button--icon {
  pointer-events: none;
}

.pswp__video {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  padding: 25px 0;
}

.pswp__video-player {
  max-width: calc(178vh - 90px);
  width: 100%;
}

.b-play_button {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.32);
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-product_gallery-thumb .b-play_button {
  background-color: rgba(0, 0, 0, 0.25);
}
.b-product_gallery-thumb .b-play_button svg {
  height: 40px;
}
.b-product_video-cover .b-play_button, .b-video_content-cover .b-play_button {
  background-color: transparent;
}
@media screen and (min-width: 768px) {
  .b-product_video-cover .b-play_button svg, .b-video_content-cover .b-play_button svg {
    height: 100px;
    width: 100px;
  }
}

body {
  overflow-y: scroll;
  pointer-events: all;
  visibility: var(--page_visibility, hidden);
}